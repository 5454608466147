import { CardHeader } from '@material-ui/core';
import type { FC } from 'react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { QueryObserverResult } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { debounceDelay, MessageLengthLimit, MessageLengthWarning, sendThrottleInterval } from './constants';
import * as Styled from './styles';
import { MessageEntry } from '../message-reply-entry';
import { MessageTextArea } from 'components/message-text-area';
import type { MessagePayload } from 'data/hooks/use-create-new-message-query';
import { useCreateNewMessage } from 'data/hooks/use-create-new-message-query';
import { useMessageThreadReply } from 'data/hooks/use-message-thread-mutation';
import { useFeatureFlags, useThrottle } from 'hooks';
import { useDebounce } from 'hooks/use-debounce';
import { useNativeControls } from 'hooks/use-native-controls';
import { useNavHeader } from 'hooks/use-nav';
import { Countly } from 'modules/countly';
import { sendToNative } from 'modules/ipc';
import type { NativePopupState } from 'modules/ipc/types';
import type { ApiResponse, MessageThread } from 'types';
import type { Provider } from 'types/provider';

interface MessageReplyProps {
    readonly messageThread: MessageThread;
    readonly messageThreadId: number;
    readonly patientProviders?: readonly Provider[];
    readonly refetchThread: () => Promise<QueryObserverResult<ApiResponse<MessageThread>>>;
}

const MessageReply: FC<MessageReplyProps> = ({ messageThread, messageThreadId, patientProviders, refetchThread }) => {
    const [replyText, setReplyText] = useState<string>('');
    const [replySent, setReplySent] = useState<boolean>(false);
    const replyTextDebounced = useDebounce<string>(replyText, debounceDelay);
    const { associated_message_thread_id, messages, message_thread_id, source, subject } = messageThread;
    const replyMutation = useMessageThreadReply(associated_message_thread_id ?? messageThreadId);
    const createNewMessageMutation = useCreateNewMessage();
    const focusRef = useRef<HTMLTextAreaElement>(null);
    const [sendInitiated, setSendInitiated] = useState(false);
    const [messageValidated, setMessageValidated] = useState(false);
    const { setNavHeaderState } = useNavHeader();

    const { hasFeature } = useFeatureFlags();
    const navigate = useNavigate();

    const onReplyChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback((event) => {
        const userInput = event.currentTarget.value;
        setReplyText(userInput);
    }, []);

    useEffect(() => {
        Countly.addEvent({
            key: 'replyMessage', // Track user clicking reply button
            segmentation: {
                messageThreadId,
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, [messageThreadId]);

    const cancelButtonFunction = useCallback(() => {
        sendToNative('showPopup', 'messaging', {
            buttonCancel: {
                text: 'Cancel',
                type: 'primary',
            },
            buttonOK: {
                action: 'discardMessage',
                text: 'Discard',
                type: 'secondary',
            },
            text: 'Your message will not be saved',
            title: 'Discard message?',
        } as NativePopupState);
    }, []);

    useEffect(() => {
        const isValidInput = replyTextDebounced !== '';
        sendToNative('showDiscardPopup', 'messaging', {
            value: isValidInput,
        });
        setNavHeaderState((navHeaderState) => ({
            ...navHeaderState,
            backButton: !isValidInput,
            closeButton: isValidInput ? cancelButtonFunction : false,
        }));
    }, [cancelButtonFunction, replyTextDebounced, setNavHeaderState]);

    const handleSendReply = useCallback(() => {
        replyMutation.mutate(replyText.trim());
    }, [replyMutation, replyText]);

    const providerObject = useMemo(() => {
        const rootMessageIndex = messages.length - 1;
        const rootMessage = messages[rootMessageIndex];
        const provider = rootMessage.to?.providerid ? rootMessage.to : rootMessage.from;
        const liveProviderObject = patientProviders?.find((element) => element.providerid === provider?.providerid);
        return liveProviderObject;
    }, [messages, patientProviders]);

    const handleComposeLabMessage = useCallback(() => {
        const payload = {
            associated_lab_result_id: message_thread_id,
            department_id: providerObject?.department_ids && providerObject.department_ids[0],
            message_type: 'PATIENTCASE_LABS',
            provider_id: providerObject?.providerid,
            subject: `Clinical Question - Lab Result ${message_thread_id}`,
            text: replyText,
        } as MessagePayload;

        createNewMessageMutation.mutate(payload);
    }, [message_thread_id, providerObject?.department_ids, providerObject?.providerid, replyText, createNewMessageMutation]);

    const handleComposeImagingResultMessage = useCallback(() => {
        const payload = {
            associated_imaging_result_id: message_thread_id,
            department_id: providerObject?.department_ids && providerObject.department_ids[0],
            message_type: 'PATIENTCASE_IMAGING_RESULTS',
            provider_id: providerObject?.providerid,
            subject: `Clinical Question - Imaging Result ${message_thread_id}`,
            text: replyText,
        } as MessagePayload;

        createNewMessageMutation.mutate(payload);
    }, [message_thread_id, providerObject?.department_ids, providerObject?.providerid, replyText, createNewMessageMutation]);

    const handleSendMessage = useCallback(() => {
        if (replyMutation.isLoading || createNewMessageMutation.isLoading) return;
        setSendInitiated(true);
    }, [replyMutation.isLoading, createNewMessageMutation.isLoading]);

    const trackComposeMessageSent = useCallback((): void => {
        const messageType = source === 'labresult' ? 'PATIENTCASE_LABS' : 'PATIENTCASE_IMAGING';
        Countly.addEvent({
            key: 'composeMessageSent', // Track message creation attempt
            segmentation: {
                messageThreadId,
                messageType,
                messagingVersion: 2,
                source: 'messaging',
            },
        });
    }, [messageThreadId, source]);

    useEffect(() => {
        if (messageValidated && !replySent) {
            if (associated_message_thread_id || source === 'provider') {
                handleSendReply();
            } else if (source === 'labresult') {
                handleComposeLabMessage();
            } else if (source === 'imagingresult') {
                handleComposeImagingResultMessage();
            }
            setReplySent(true);
            trackComposeMessageSent();
        }
    }, [
        associated_message_thread_id,
        handleComposeImagingResultMessage,
        handleComposeLabMessage,
        handleSendReply,
        messageValidated,
        replySent,
        source,
        trackComposeMessageSent,
    ]);

    const throttledHandleSendMessage = useThrottle(handleSendMessage, sendThrottleInterval);

    useEffect(() => {
        if (!replySent) return;

        async function runAsync(): Promise<void> {
            const isSuccessfulReply = replyMutation.isSuccess && replyMutation.data?.success;
            const isSuccessfulNewMessage = createNewMessageMutation.isSuccess && createNewMessageMutation.data?.message_thread_id;

            if (isSuccessfulReply || isSuccessfulNewMessage) {
                await refetchThread();
                sendToNative('showDiscardPopup', 'messaging', {
                    value: false, // Ensure native does not show popup when a reply is successful!
                });
                sendToNative('replyMessageSent', 'messaging', {
                    value: true,
                });
                if (hasFeature('messaging.backAfterReply')) {
                    navigate(-1);
                }
            } else if (replyMutation.error || createNewMessageMutation.error) {
                sendToNative('replyMessageSent', 'messaging', {
                    value: false,
                });
                setReplySent(false);
                setMessageValidated(false);
            }
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        runAsync();
    }, [
        replySent,
        createNewMessageMutation.isSuccess,
        createNewMessageMutation.data,
        createNewMessageMutation.error,
        replyMutation.isSuccess,
        replyMutation.error,
        replyMutation.data,
        hasFeature,
        navigate,
        refetchThread,
    ]);

    useEffect(
        () => () => {
            sendToNative('showDiscardPopup', 'messaging', {
                value: false, // Ensure native does not show popup when a reply is successful!
            });
        },
        []
    );

    const messageUserAvatar = useMemo(
        () =>
            providerObject?.image?.url ? (
                <Styled.MessageAvatar alt={providerObject?.displayname ?? ''} src={providerObject.image.url} />
            ) : (
                <Styled.MessageAvatar>
                    <Styled.DefaultProvider name="icProvider" size={2.5} />
                </Styled.MessageAvatar>
            ),
        [providerObject?.displayname, providerObject?.image?.url]
    );

    useNativeControls({
        ipcEvents: ['showBackButton', 'showSendButton'],
        sendButtonFunction: throttledHandleSendMessage,
        source: 'messaging',
        title: 'Reply',
    });

    useEffect(() => {
        document.addEventListener('sendReplyMessage', throttledHandleSendMessage);
        document.addEventListener('sendNewMessage', throttledHandleSendMessage);
        return () => {
            document.removeEventListener('sendReplyMessage', throttledHandleSendMessage);
            document.removeEventListener('sendNewMessage', throttledHandleSendMessage);
        };
    }, [throttledHandleSendMessage]);

    const messageThreadList = useMemo(
        () => messages.map((message) => <MessageEntry key={message.timestamp} message={message} />),
        [messages]
    );

    useEffect(() => {
        focusRef.current?.focus();
    }, []);

    return (
        <Styled.Container>
            <Styled.ReplyCard>
                <CardHeader
                    avatar={messageUserAvatar}
                    title={
                        <Fragment>
                            <Styled.Sender type="body2">To: {providerObject?.displayname ?? 'Provider'}</Styled.Sender>
                            <Styled.Subject type="body1">{subject}</Styled.Subject>
                        </Fragment>
                    }
                />
                <Styled.BorderCity>
                    <MessageTextArea
                        ref={focusRef}
                        autoFocus={true}
                        data-testid="reply-input"
                        disabled={replyMutation.isLoading || createNewMessageMutation.isLoading}
                        maxRows={7}
                        message={replyText}
                        messageLengthLimit={MessageLengthLimit}
                        messageLengthWarning={MessageLengthWarning}
                        messagePlaceholder="Type your message"
                        minRows={7}
                        onChangeMessage={onReplyChange}
                        sendTriggered={sendInitiated}
                        setSendTriggered={setSendInitiated}
                        setValidated={setMessageValidated}
                    />
                </Styled.BorderCity>
            </Styled.ReplyCard>
            <Styled.MessagesList>{messageThreadList}</Styled.MessagesList>
        </Styled.Container>
    );
};

export { MessageReply };
